import axios, {AxiosResponse} from 'axios'
import {AuthModel, UserModel} from './_models'
import {Response} from '../../../../_metronic/helpers/crud-helper/custom-models'
import {GetAmazonAccountsQueryResponse} from "../../amazon-account/amazon-accounts-list/core/_models";

const API_URL = process.env.REACT_APP_KT_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify-token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const VERIFY_RESET_PASSWORD_URL = `${API_URL}/auth/verify-reset-password-link`


// Server should return AuthModel
// export function login(email: string, password: string): Promise<Response<AuthModel>> {
//     return axios.post<Response<AuthModel>>(LOGIN_URL, {
//         email,
//         password,
//     }).then((d: AxiosResponse<Response<AuthModel>>) => d.data)
// }

export function login(email: string, password: string): Promise<Response<AuthModel>> {
    return axios.post<Response<AuthModel>>(LOGIN_URL, {
        email,
        password,
    }).then((d: AxiosResponse<Response<AuthModel>>) => d.data)
}

// Server should return AuthModel
export function register(
    email: string,
    firstname: string,
    lastname: string,
    password: string,
    password_confirmation: string
) {
    return axios.post(REGISTER_URL, {
        email,
        first_name: firstname,
        last_name: lastname,
        password,
        password_confirmation,
    })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<Response<{ result: boolean }>>(REQUEST_PASSWORD_URL, {
        email,
    }).then((d: AxiosResponse<Response<{ result: boolean }>>) => d.data)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function resetPassword(password: string, token: string) {
    return axios.patch<Response<{ result: boolean }>>(RESET_PASSWORD_URL, {
        new_password: password,
        reset_token: token
    }).then((d: AxiosResponse<Response<{ result: boolean }>>) => d.data)
}

export function verifyResetPasswordLink(token: string) {
    return axios.post<Response<{ result: boolean }>>(VERIFY_RESET_PASSWORD_URL, {
        reset_token: token
    }).then((d: AxiosResponse<Response<{ result: boolean }>>) => d.data)
}

export function getUserByToken(): Promise<Response<UserModel>> {
    return axios.get<Response<UserModel>>(GET_USER_BY_ACCESSTOKEN_URL)
        .then((d: AxiosResponse<Response<UserModel>>) => d.data)
}
