import React, { useEffect, useState } from 'react'
import { KTCard } from '../../../_metronic/helpers'
import { ProductsListSearchComponent } from '../../modules/products/products-list/components/header/ProductsListSearchComponent'
import { useAuth } from '../../modules/auth'
import ReactPaginate from 'react-paginate'
import { Tooltip } from 'react-tooltip'
import { toast } from 'react-toastify'
// import { UsersListPagination } from '../../modules/apps/IBS-product-sync/users-list/components/pagination/UsersListPagination'
// import { useQueryResponseLoading } from '../../modules/apps/IBS-product-sync/users-list/core/QueryResponseProvider'
// import { UsersListLoading } from '../../modules/apps/IBS-product-sync/users-list/components/loading/UsersListLoading'

function TotalproductsShow() {
    let { currentUser } = useAuth();
    const API_URL = process.env.REACT_APP_KT_API_URL
    const tokenString: any = localStorage.getItem('kt-auth-react-v')
    const token = JSON.parse(tokenString)
    const [productdata, setProductData] = useState<any>([])
    const [searchBar, setSearchbar] = useState({ search: '' });
    const [total_pages, setTotal_pages] = useState<any>(null)
    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 10; // number of items per pagen;

    // toast center align
    const toastError = (errorMessage) => {
        toast.error(errorMessage, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000
        });
    };


    // total_products
    const total_products = async () => {
        try {
            let params = new URLSearchParams();
            if (currentUser?.roles && currentUser.roles.length > 0) {
                const userId = currentUser?.id
                const userType = currentUser.roles[0]?.id;
                if (userType !== null && userType !== undefined) {
                    params.append("user_type_id", userType?.toString())
                    params.append("userId", userId?.toString())
                    if (searchBar.search !== "") {
                        params.append("search", searchBar?.search?.toString())
                    }
                }
            }
            const queryString = params.toString();
            const response = await fetch(`${API_URL}/dashboard/GetAllProducts?page=${currentPage}&limit=${perPage}&${queryString}`, {
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json()
            if (!response.ok) {
                toastError(data?.message)
            }
            // const itemsWithSerialNumbers = data?.data?.items.map((item, index) => ({
            //     ...item,
            //     serialNumber: (currentPage - 1) * perPage + index + 1
            // }));
            setProductData(data?.data?.items)
            // setProductData(itemsWithSerialNumbers)
            setTotal_pages(data?.data?.meta?.totalPages)
        } catch (error) {
            console.error(error)
        }
    }

    const getDataTotalProducts: (value: any) => void = (value) => {
        setSearchbar((search) => ({ ...search, search: value }))
    };
    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1); // Update currentPage state with the selected page number
    };

    useEffect(() => {
        total_products()
    }, [searchBar, currentPage])
    return (
        <React.Fragment>
            <KTCard>
                <div className='card-header border-0 pt-6'>
                    <ProductsListSearchComponent getDataTotalProducts={getDataTotalProducts} />
                    <div className='table-responsive pt-6 w-100'>
                        <Tooltip id='my-tooltip' />
                        <table className='table align-middle table-bordered table-row-dashed gy-5 dataTable no-footer rounded'>
                            <thead>
                                <tr className='fw-bold text-dark text-uppercase'>
                                    <th className='ps-4 w-60px'>S.No</th>
                                    {/* <th>Product Name</th> */}
                                    <th>ITEM SKU</th>
                                    <th>SKU DESCRIPTION</th>
                                    <th>
                                        <div>
                                            Cost Price
                                            <span
                                                className='svg-icon svg-icon-primary svg-icon-2x ms-2 cursor-pointer'
                                                data-tooltip-id='my-tooltip'
                                                data-tooltip-content='Weighted Avg. Cost Price'
                                            >
                                                <svg xmlns='http://www.w3.org/2000/svg'
                                                    width='24px'
                                                    height='24px'
                                                    viewBox='0 0 24 24'
                                                    version='1.1' >
                                                    <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
                                                        {' '}
                                                        <rect x='0' y='0' width='24' height='24'></rect>
                                                        <circle fill='#000000' opacity='0.2' cx='12' cy='12' r='10'></circle>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='10'
                                                            width='2'
                                                            height='7'
                                                            rx='1'
                                                        ></rect>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='7'
                                                            width='2'
                                                            height='2'
                                                            rx='1'
                                                        ></rect>
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                    </th>
                                    <th>created </th>
                                    <th>updated </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    productdata && productdata.length > 0 ?
                                        productdata?.map?.((item, index) => {
                                            const created_at = new Date(item?.created_at)?.toISOString()?.split('T')[0]?.split('-')?.reverse()?.join('-');
                                            const updated_at = new Date(item?.updated_at)?.toISOString()?.split('T')[0]?.split('-')?.reverse()?.join('-');
                                            return (
                                                <>
                                                    <tr key={(perPage * (currentPage - 1) + index + 1)}>
                                                        <td className='ps-4 w-60px'>{(perPage * (currentPage - 1) + index + 1)}</td>
                                                        {/* <td>{item.name}</td> */}
                                                        <td>{item.sku}</td>
                                                        <td>{item.description}</td>
                                                        <td>${item.cost_price}</td>
                                                        <td>{created_at}</td>
                                                        <td>{updated_at}</td>
                                                    </tr>
                                                </>
                                            )
                                        })
                                        : <tr>
                                            <td colSpan={14}>
                                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                    No matching records found
                                                </div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                        productdata && productdata?.length > 0 &&
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={total_pages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination justify-content-end mt-2 mb-3 ms-auto"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        />
                    }
                </div>
            </KTCard>
        </React.Fragment >
    )
}

export default TotalproductsShow