import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {initialQueryState} from '../../../../../../_metronic/helpers/crud-helper/custom-models'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {stat} from "fs";

const CurrencyListFilter = () => {
    const {updateState, state} = useQueryRequest()
    const {isLoading} = useQueryResponse()
    const [priceType, setPriceType] = useState<string>(state.filter.price_type)

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const resetData = () => {
        setPriceType('');
        delete state.filter.price_type;
        updateState({...state, filter: {...state.filter}})
    }

    const filterData = () => {
        updateState({
            ...state,
            filter: {
                ...state.filter,
                price_type: priceType
            }
        })
    }

    return (
        <>
            {/* begin::Filter Button */}
            <button
                disabled={isLoading}
                type='button'
                className='btn btn-light-primary me-3 btn-sm py-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                <KTIcon iconName='filter' className='fs-2'/>
                Filter
            </button>
            {/* end::Filter Button */}
            {/* begin::SubMenu */}
            <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                {/* begin::Header */}
                <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bold'>Filter Options</div>
                </div>
                {/* end::Header */}

                {/* begin::Separator */}
                <div className='separator border-gray-200'></div>
                {/* end::Separator */}

                {/* begin::Content */}
                <div className='px-7 py-5' data-kt-role-table-filter='form'>
                    {/*begin::Input group */}
                    <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Price Type</label>
                        <select
                            placeholder="Select Type"
                            className='form-select form-select-solid fw-bold'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            data-kt-role-table-filter='price_type'
                            data-hide-search='true'
                            onChange={(e) => setPriceType(e.target.value)}
                            value={priceType}
                        >
                            <option value=''>Select Type</option>
                            <option value='normal'>Normal</option>
                            <option value='introductory'>Introductory</option>
                            <option value='sale'>Sale</option>
                        </select>
                    </div>
                    {/*end::Input group */}

                    {/* begin::Actions */}
                    <div className='d-flex justify-content-end'>
                        <button
                            type='button'
                            disabled={isLoading}
                            onClick={resetData}
                            className='btn btn-light btn-active-light-primary btn-sm fw-bold me-2 px-6 py-3'
                            data-kt-menu-dismiss='true'
                            data-kt-role-table-filter='reset'
                        >
                            Reset
                        </button>
                        <button
                            disabled={isLoading}
                            type='button'
                            onClick={filterData}
                            className='btn btn-primary fw-bold px-6 btn-sm py-3'
                            data-kt-menu-dismiss='true'
                            data-kt-role-table-filter='filter'
                        >
                            Apply
                        </button>
                    </div>
                    {/* end::Actions */}
                </div>
                {/* end::Content */}
            </div>
            {/* end::SubMenu */}
        </>
    )
}

export {CurrencyListFilter}
