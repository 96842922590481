import {toast} from "react-toastify";
import {string} from "yup";

export const handleError = (ex: any) => {
    if(Array.isArray(ex.response.data.message)){
        toast.error(ex.response.data.message.join('<br/>'));
    }
    if(typeof ex.response.data.message === 'string' || ex.response.data.message instanceof string){
        toast.error(ex.response.data.message);
    }
}