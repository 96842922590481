import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers/crud-helper/custom-models'
import {Currency, GetCurrencyQueryResponse} from './_models'

// const API_URL = process.env.REACT_APP_API_URL
const API_URL = 'https://openexchangerates.org/'
const CURRENCY_API_URL = `${API_URL}/api/currencies.json`

// const API_URL = 'https://json-server.dikonia.in'
// const CURRENCY_API_URL = `${API_URL}/products`



// const getCurrency = (query: string): Promise<GetCurrencyQueryResponse> => {
//     return axios
//       .get(`${CURRENCY_API_URL}?${query}`)
//       .then((response: AxiosResponse<GetCurrencyQueryResponse>) => {
//         console.log('data:', response.data);
  
//         const currencies = {
//           AED: 'United Arab Emirates Dirham',
//           AFN: 'Afghan Afghani',
//           // ... (rest of the currencies)
//           ZAR: 'South African Rand',
//           ZMW: 'Zambian Kwacha',
//           ZWL: 'Zimbabwean Dollar',
//         };
  
//         const currencyArray: Currency[] = Object.keys(currencies).map((currencyCode, index) => ({
//           id: index + 1,
//           code: currencyCode,
//           name: currencies[currencyCode],
//         }));
  
//         console.log('currencyArray:', currencyArray);
  
//         // Adjust the return type to match GetCurrencyQueryResponse
//         return {
//           currencies: currencyArray,
//           // other properties if needed
//         };
//       });
//   };
const getCurrency = (query: string): Promise<GetCurrencyQueryResponse> => {
    return axios
        .get(`${CURRENCY_API_URL}?${query}`)
        .then((d: AxiosResponse<GetCurrencyQueryResponse>) => {
            console.log("dtaavvvvv=-=--=" , d.data)
            return d.data})
} 

// const getCurrency = (query: string): Promise<Currency[]> => {
//     return axios
//       .get(`${CURRENCY_API_URL}?${query}`)
//       .then((response: AxiosResponse<Record<string, string>>) => {
//         const data = response.data;
//         const currencyArray: Currency[] = Object.keys(data).map((currencyCode, index) => {
//           // Check if currencyCode is a valid key in the response object
//           if (!data.hasOwnProperty(currencyCode)) {
//             throw new Error(`Invalid currency code: ${currencyCode}`);
//           }
  
//           return {
//             id: index + 1, // Assign a unique ID, adjust the logic based on your requirements
//             code: currencyCode,
//             name: data[currencyCode]
//           };
//         });
  
//         return currencyArray;
//       });
//   };
  

const getCurrencyById = (id: ID): Promise<Currency | undefined> => {
    return axios
        .get(`${CURRENCY_API_URL}/${id}`)
        .then((response: AxiosResponse<Response<Currency>>) => response.data)
        .then((response: Response<Currency>) => response.data)
}

const createCurrency = (product: Currency): Promise<Currency | undefined> => {
    return axios
        .post(CURRENCY_API_URL, product)
        .then((response: AxiosResponse<Response<Currency>>) => response.data)
        .then((response: Response<Currency>) => response.data)
}

const updateCurrency = (product: Currency): Promise<Currency | undefined> => {
    return axios
        .patch(`${CURRENCY_API_URL}/${product.id}`, product)
        .then((response: AxiosResponse<Response<Currency>>) => response.data)
        .then((response: Response<Currency>) => response.data)
}

const deleteCurrency = (product_id: ID): Promise<void> => {
    return axios.delete(`${CURRENCY_API_URL}/${product_id}`).then(() => {
    })
}

const deleteSelectedCurrency = (product_ids: Array<ID>): Promise<void> => {
    const requests = product_ids.map((id) => axios.delete(`${CURRENCY_API_URL}/${id}`))
    return axios.all(requests).then(() => {
    })
}

export {getCurrency, deleteCurrency, deleteSelectedCurrency, getCurrencyById, createCurrency, updateCurrency}
