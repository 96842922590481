const QUERIES = {
  USERS_LIST: 'users-list',
  ROLES_LIST: 'roles-list',
  ALL_ROLES_LIST: 'all-role-list',
  BUYERS_LIST: 'users-list',
  PRODUCTS_LISTS: 'products-list',
  AMAZON_ACCOUNTS: 'amazon-accounts',
  PERMISSIONS_LIST: 'permissions-list',
  CHANNELS_LIST: 'channels-list',
  CURRENCY_LIST: 'currency-list',
  // BUYER_CODES_LIST: 'buyer-codes-list',
}

export {QUERIES}
