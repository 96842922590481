/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Currency} from '../../../../currency/currency-list/core/_models'

type Props = {
    price: number
}

const CurrencyPriceCell: FC<Props> = ({price}) => (
    <div className='d-flex align-items-center'>
        <span>{`$${price}`}</span>
    </div>
)

export {CurrencyPriceCell}
