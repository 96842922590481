// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const channelDetailEditSlice = createSlice({
  name:"channelDetailEdit",
  initialState:"",
  reducers:{
    UpdatechannelEditAction(state,action){
      return (action.payload)
    },
    ResetUpdatechannelEditAction(){
      return ""
    }
  }
}) 
export default channelDetailEditSlice.reducer;
export const {UpdatechannelEditAction,ResetUpdatechannelEditAction}= channelDetailEditSlice.actions ;