import {FC, useState, createContext, useContext} from 'react'
import {
  WithChildren
} from '../../../../../_metronic/helpers'
import { QueryState, QueryRequestContextProps, initialQueryRequest } from '../../../../../_metronic/helpers/crud-helper/custom-models'
import {useSearchParams} from "react-router-dom";
const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [urlQueryString, setUrlQueryString] = useState({"filter": {}});
  const [searchParams] = useSearchParams();
  searchParams.forEach((value, key) => {
    if (key.startsWith("filter_")) {
      urlQueryString['filter'][key.substring(7)] = value; // Extract the remaining string after "filter_"
      return;
    }
    urlQueryString[key] = value;
  });

  const [state, setState] = useState<QueryState>({
    ...initialQueryRequest.state,
    ...urlQueryString,
  })

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as QueryState
    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
