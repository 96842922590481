// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const channelIdActionSlice = createSlice({
  name:"channelIdAction",
  initialState:"",
  reducers:{
    UpdateChannelAction(state,action){
      return (action.payload)
    },
    ResetUpdateChannelAction(){
      return ""
    }
  }
}) 
export default channelIdActionSlice.reducer;
export const {UpdateChannelAction,ResetUpdateChannelAction}= channelIdActionSlice.actions ;