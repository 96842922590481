import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {CurrencyListHeader} from './components/header/CurrencyListHeader'
import {CurrencyTable} from './table/CurrencyTable'
// import {CurrencyEditModal} from './currency-edit-modal@old/CurrencyEditModal'
// import {CurrencyReassignModal} from "./currency-reassign-modal@old/CurrencyReassignModal";
import {KTCard} from '../../../../_metronic/helpers'
import {FC} from "react";
import {Column} from "react-table";
import {Currency} from "./core/_models";

export type CurrencyListProps = {
    displayUnassignedFilter: boolean
    displayHideFilter: boolean
    currencyColumns: ReadonlyArray<Column<Currency>>
}

export type CurrencyListWrapperProps = {
    displayUnassignedFilter: boolean
    displayHideFilter: boolean
    currencyColumns: ReadonlyArray<Column<Currency>>
}
const CurrencyList: FC<CurrencyListProps> = ({currencyColumns, displayUnassignedFilter, displayHideFilter}) => {
    const {itemIdForUpdate, itemIdForReassign} = useListView()
    return (
        <>
            <KTCard>
                <CurrencyListHeader displayUnassignedFilter={displayUnassignedFilter} displayHideFilter={displayHideFilter}/>
                <CurrencyTable currency_columns={currencyColumns}/>
            </KTCard>
            {/* {itemIdForUpdate !== undefined && <CurrencyEditModal/>} */}
            {/* {itemIdForReassign !== undefined && <CurrencyReassignModal/>} */}
        </>
    )
}

const CurrencyListWrapper: FC<CurrencyListWrapperProps> = ({currencyColumns, displayUnassignedFilter, displayHideFilter}) => (
    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <CurrencyList currencyColumns={currencyColumns} displayUnassignedFilter={displayUnassignedFilter} displayHideFilter={displayHideFilter}/>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export {CurrencyListWrapper}
