/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  QUERIES,
  WithChildren,
} from '../../../../../_metronic/helpers'
import { createResponseContext, stringifyRequestQuery }  from '../../../../../_metronic/helpers/crud-helper/custom-helpers'
import { initialQueryResponse, initialQueryState, PaginationState }from "../../../../../_metronic/helpers/crud-helper/custom-models"
import {getCurrency} from './_requests'
import {Currency} from './_models'
import {useQueryRequest} from './QueryRequestProvider'

const QueryResponseContext = createResponseContext<Currency>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.PRODUCTS_LISTS}-${query}`,
    () => {
      return getCurrency(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  // const currency_data = {...response}
  // console.log("response--------------in provider" , response)
  // console.log("response--------------in provider currency data" , response)
  // const currencyArray = Object.keys(currency_data).map((currencyCode, index) => {
  //   // Check if currencyCode is a valid key in the response object
  //   if (!currency_data.hasOwnProperty(currencyCode)) {
  //     console.error(`Invalid currency code: ${currencyCode}`);
  //     return null;
  //   }

  //   return {
  //     id: index + 1, // Assign a unique ID, adjust the logic based on your requirements
  //     code: currencyCode,
  //     name: currency_data[currencyCode]
  //   };
  // }).filter(currency => currency !== null); // Filter out null values caused by invalid currency codes

  // console.log("currrrrrrrrr00-0-00-0-", currencyArray);


  return (

     <>
    {



    }
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
    </>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  let {response} = useQueryResponse()

  // const currencies = {
  //   "AED": "United Arab Emirates Dirham",
  //   "AFN": "Afghan Afghani",
  //   // ... (rest of the currencies)
  //   "ZAR": "South African Rand",
  //   "ZMW": "Zambian Kwacha",
  //   "ZWL": "Zimbabwean Dollar"
  // };
  // let val=1;
  // const currencyArray = Object.keys(currencies).map(currencyCode => ({
  //   id: val++,
  //   code: currencyCode,
  //   name: currencies[currencyCode]
  // }));
  
  // console.log("currrrrrrrrr00-0-00-0-", currencyArray);
  // if (!currencyArray) {
  //   return []
  // }
  
  // console.log("res=-=-=-=-=" , response)
   
  // return currencyArray || []
  if (!response) {
    return []
  }
  
  console.log("res=-=-=-=-=" , response)
  
  return response?.data?.items || []
  // return currencyArray || []
  if (!response) {
    return []
  }
  
  console.log("res=-=-=-=-=" , response)
  
  return response?.data?.items || []
}

const useQueryResponsePagination = () => {
  let defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()

  if (!response || !response.data || !response.data.meta) {
    return defaultPaginationState
  }

  defaultPaginationState.page = response.data.meta.currentPage
  defaultPaginationState.limit = response.data.meta.itemsPerPage
  let pageLinks: Array<{
    label: string
    active: boolean
    url: string | null
    page: number | null
  }> = []

  const totalPages:number = response.data.meta.totalPages;
  for (let i = 0; i < totalPages; i++){
    pageLinks.push({
      label: `${i+1}`,
      active: false,
      url: `?page=${i+1}`,
      page: i+1
    })
  }
  defaultPaginationState.links = pageLinks;

  return defaultPaginationState;
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
