// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const productActionSlice = createSlice({
  name:"productAction",
  initialState:"",
  reducers:{
    UpdateAction(state,action){
      return (action.payload)
    },
    ResetUpdateAction(){
      return ""
    }
  }
}) 
export default productActionSlice.reducer;
export const {UpdateAction,ResetUpdateAction}= productActionSlice.actions;