// @ts-nocheck
import {Column} from 'react-table'
import {CurrencyActionsCell} from './CurrencyActionsCell'
import {CurrencySelectionCell} from './CurrencySelectionCell'
import {CurrencyCustomHeader} from './CurrencyCustomHeader'
import {CurrencySelectionHeader} from './CurrencySelectionHeader'
import {CurrencyInfoCell} from './CurrencyInfoCell'
import {Currency} from '../../core/_models'
import {CurrencyBuyerNameCell} from "./CurrencyBuyerNameCell";
import {CurrencyPriceCell} from "./CurrencyPriceCell";
import {CurrencyBuyerCodeCell} from "./CurrencyBuyerCodeCell";

// console.log( "currencyy in col-------" ,Currency )
const currencyColumns: ReadonlyArray<Column<Currency>> = [
  // {
  //   Header: (props) => <CurrencySelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <CurrencySelectionCell id={props.data[props.row.index].id} />,
  // },
  // {
  //   Header: (props) => <CurrencyCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
  //   id: 'name',
  //   Cell: ({...props}) => <CurrencyInfoCell product={props.data[props.row.index]} />,
  // },
  // {
  //   Header: (props) => <CurrencyCustomHeader tableProps={props} title='SKU' className='min-w-125px' />,
  //   accessor: 'sku',
  // },
  // {
  //   Header: (props) => <CurrencyCustomHeader tableProps={props} title='Quantity' className='min-w-125px' />,
  //   accessor: 'quantity',
  // },
  // {
  //   Header: (props) => <CurrencyCustomHeader tableProps={props} title='Buyer Code' className='min-w-125px' />,
  //   id: 'buyer_code',
  //   Cell: ({...props}) => <CurrencyBuyerCodeCell product={props.data[props.row.index]} />,
  // },
  // {
  //   Header: (props) => <CurrencyCustomHeader tableProps={props} title='WEIGHTED AVG COST' className='min-w-125px' />,
  //   id: 'weighted_average_cost',
  //   Cell: ({...props}) => <CurrencyPriceCell price={props.data[props.row.index].weighted_average_cost} />,
  // },
  // {
  //   Header: (props) => <CurrencyCustomHeader tableProps={props} title='Price' className='min-w-125px' />,
  //   id: 'price',
  //   Cell: ({...props}) => <CurrencyPriceCell price={props.data[props.row.index].price} />,
  // },
  // {
  //   Header: (props) => <CurrencyCustomHeader tableProps={props} title='CURRENCY CODE' className='min-w-125px' />,
  //   id: 'currency_code',
  //   Cell: ({...props}) => <CurrencyPriceCell price={props.data[props.row.index].AED} />,
  // },
  // {
  //   Header: (props) => <CurrencyCustomHeader tableProps={props} title='CURRENCY NAME' className='min-w-125px' />,
  //   id: 'currency_name',
  //   Cell: ({...props}) => <CurrencyPriceCell price={props.data[props.row.index].AED} />,
  // },
  {
    Header: (props) => <CurrencyCustomHeader tableProps={props} title='CURRENCY CODE' className='min-w-125px' />,
    accessor: 'currency_code', // This matches the property name in your data
    Cell: ({ value }) => <CurrencyPriceCell price={value} />,
  },
  {
    Header: (props) => <CurrencyCustomHeader tableProps={props} title='CURRENCY NAME' className='min-w-125px' />,
    accessor: 'currency_name', // This matches the property name in your data
    Cell: ({ value }) => <CurrencyPriceCell price={value} />,
  },
  {
    Header: (props) => (
      <CurrencyCustomHeader tableProps={props} title='Actions' className='text-center min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CurrencyActionsCell id={props.data[props.row.index].id} />,
  },
]

export {currencyColumns}
