// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const updateBuyerCode = createSlice({
  name:"updateBuyerCode",
  initialState:false,
  reducers:{
    updateBuyerCodeAction(state,action){
      return (action.payload)
    },
  }
}) 
export default updateBuyerCode.reducer;
export const {updateBuyerCodeAction}= updateBuyerCode.actions;