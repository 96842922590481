// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const hiddenproducts = createSlice({
  name:"hiddenproducts",
  initialState: {
    isToggled: false,
  },
  reducers:{
    showhiddenProducts: state => {
        state.isToggled = !state.isToggled;
      },
  }
}) 
export default hiddenproducts.reducer;
export const {showhiddenProducts} = hiddenproducts.actions;