// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import productActionSlice from './productActionSlice';
import channelIdActionSlice from './channelIdActionSlice';
import channelDetailEditSlice from './channelDetailEditSlice';
import  showhiddenProducts  from './hiddenproducts';
import updateBuyerCode from './updateBuyerCode';
import azureId from './azureId';
import azureIdOpen from './azureIdOpen';
const store = configureStore({
  reducer: {
    productionAction: productActionSlice,
    channelIdAction:  channelIdActionSlice,
    updateBuyerCode:updateBuyerCode,
    channelDetailEdit:channelDetailEditSlice,
    showhiddenProducts : showhiddenProducts,
    azureId:azureId,
    azureIdOpen:azureIdOpen
    // Add more reducers if needed
  }
});
export default store;
