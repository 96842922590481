/* eslint-disable react-hooks/exhaustive-deps */
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useState} from "react";

const CurrencyListHideFilterComponent = () => {
    const {updateState, state} = useQueryRequest()
    const [isChecked, setIsChecked] = useState<boolean>(state.filter.unassigned !== undefined);

    const filterData = (e) => {
        setIsChecked(!isChecked);
        if(!isChecked){
            updateState({
                ...state,
                filter: {...state.filter, hide: 1},
            })
        }else{
            delete state.filter.hide
            updateState({
                ...state,
                filter: {...state.filter},
            })
        }

    }

    return (
        // <div className='card-title'>
            <div className='d-flex align-items-center my-1'>
                <div className="form-check form-check-custom form-check-solid">
                    <input
                        className='form-check-input'
                        type='checkbox'
                        onChange={filterData}
                        checked={isChecked}
                    />
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                        Show Hide Currency
                    </label>
                </div>
            </div>
        // </div>
    )
}

export {CurrencyListHideFilterComponent}
