import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { CurrencyListWrapper } from './currency-list/CurrencyList'
import { CurrencyHeader } from "./detail/CurrencyHeader";
import { Detail } from "./detail/components/Detail";
import { CurrencyFeeds } from "./detail/components/CurrencyFeeds";
import { Users } from "./detail/components/Users";
import { currencyColumns } from "./currency-list/table/columns/_columns";
import { QueryResponseProvider } from "./detail/components/core/QueryResponseProvider";

const CurrencyBreadcrumbs: Array<PageLink> = [
    {
        title: 'Manage Currency',
        path: '/currency/list',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const currencyPofileBreadcrumbs: Array<PageLink> = [
    {
        title: 'Manage Currency',
        path: '/currency/list',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    }
]


const CurrencyPage = () => (
    <Routes>
        <Route element={<Outlet />}>
            <Route
                path='/list'
                element={
                    <>
                        <PageTitle breadcrumbs={CurrencyBreadcrumbs}>Currency list</PageTitle>
                        <CurrencyListWrapper currencyColumns={currencyColumns} displayUnassignedFilter={true} displayHideFilter={true} />
                    </>
                }
            />
            <Route path='/*' element={<Navigate to='/error/404' />} />
        </Route>
        <Route index element={<Navigate to='/currency/list' />} />
    </Routes>
)


export default CurrencyPage
