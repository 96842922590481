import {useListView} from '../../core/ListViewProvider'
import {CurrencyListFilter} from './CurrencyListFilter'

const ProductListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddProductModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-product-table-toolbar='base'>
      <CurrencyListFilter />

      {/* begin::Export */}
      {/*<button type='button' className='btn btn-light-primary me-3'>*/}
      {/*  <KTIcon iconName='exit-up' className='fs-2' />*/}
      {/*  Export*/}
      {/*</button>*/}
      {/* end::Export */}

      {/* begin::Add product */}
      {/*<button type='button' className='btn btn-primary' onClick={openAddProductModal}>*/}
      {/*  <KTIcon iconName='plus' className='fs-2' />*/}
      {/*  Add Product*/}
      {/*</button>*/}
      {/* end::Add product */}
    </div>
  )
}

export {ProductListToolbar}
