import {useListView} from '../../core/ListViewProvider'
import {ProductListToolbar} from './CurrencyListToolbar'
import {CurrencyListGrouping} from './CurrencyListGrouping'
import {CurrencyListSearchComponent} from './CurrencyListSearchComponent'
import {CurrencyListAmazonAccountComponent} from "./CurrencyListAmazonAccountComponent";
import {CurrencyListUnassignedFilterComponent} from "./CurrencyListUnassignedFilterComponent";
import {FC} from "react";
import {CurrencyListHideFilterComponent} from "./CurrencyListHideFilterComponent";

export type CurrencyListHeaderProps = {
    displayUnassignedFilter: boolean
    displayHideFilter: boolean
}
const CurrencyListHeader: FC<CurrencyListHeaderProps> = ({displayUnassignedFilter, displayHideFilter}) => {
    const {selected} = useListView()
    return (
        <div className='card-header border-0 pt-6'>
            {/*<div className='card-title d-flex'>*/}
            <CurrencyListSearchComponent/>
            {displayUnassignedFilter && <CurrencyListUnassignedFilterComponent/>}
            {displayHideFilter && <CurrencyListHideFilterComponent/>}
            <CurrencyListAmazonAccountComponent/>
            {/*</div>*/}
            {/* begin::Card toolbar */}
            <div className='card-toolbar'>
                {/* begin::Group actions */}
                {selected.length > 0 ? <CurrencyListGrouping/> : <ProductListToolbar/>}
                {/* end::Group actions */}
            </div>
            {/* end::Card toolbar */}
        </div>
    )
}

export {CurrencyListHeader}
